<template>
  <!-- 号码池页面 -->

  <div class="number">
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          <span>虚拟号列表</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <div class="ml-10">
            <el-button
              icon="el-icon-search"
              class="button-el"
              size="medium"
              @click="searchQuery()"
              >查询
            </el-button>
            <el-button
              icon="el-icon-plus"
              class="button-el"
              size="medium"
              v-if="$store.state.userType=='system'"
              @click="theQueryDialog3 = true"
              >批量绑定
            </el-button>
          </div>

          <div class="ml-10">
            <el-dropdown>
              <el-button class="button-el" size="medium">
                <icon-font
                  type="icon-bangdingguanxi"
                  class="button-icon-font"
                />
                批量操作
                <icon-font type="icon-copy-4-36" class="button-icon-font" />
              </el-button>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="packBranch()"
                    >勾选解绑</el-dropdown-item
                  >
                  <el-dropdown-item @click="packBranch1()"
                    >批量解绑</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="ml-10" v-if="$store.state.userType=='system'">
            <el-dropdown>
              <el-button class="button-el" size="medium">
                <icon-font
                  type="icon-bangdingguanxi"
                  class="button-icon-font"
                />
                工作号配置
                <icon-font type="icon-copy-4-36" class="button-icon-font" />
              </el-button>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="$refs.jobNumbeModal.dialogOpen = true; jobNumbeModalType = 'search'"
                    >查询</el-dropdown-item
                  >
                  <el-dropdown-item @click="$refs.jobNumbeModal.dialogOpen = true; jobNumbeModalType = 'update'"
                    >更新</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div
                class="font-refresh-out flex-nowrap-center-center"
                @click="searchTapReset(1)"
              >
                <icon-font
                  v-if="loading == false"
                  type="icon-shuaxin"
                  class="font-refresh"
                />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
          <!-- new -->
        </div>
      </el-col>
    </el-row>
    <!-- 数据列表 -->
    <el-row>
      <el-col :span="24">
        <div>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="700"
            size="mini"
            :row-style="{ height: '50px' }"
            :cell-style="{ padding: '0px' }"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="虚拟号" fixed>
              <template #default="scope"
                >{{ scope.row.telX == null ? "-" : scope.row.telX }}
              </template>
            </el-table-column>
            <el-table-column label="绑定主叫">
              <template #default="scope"
                >{{ scope.row.telA == null ? "-" : scope.row.telA }}
              </template>
            </el-table-column>
            <el-table-column label="企业名称">
              <template #default="scope"
                >{{ scope.row.nickname == null ? "-" : scope.row.nickname }}
              </template>
            </el-table-column>
            <el-table-column label="绑定ID">
              <template #default="scope"
                >{{ scope.row.bindId == null ? "-" : scope.row.bindId }}
              </template>
            </el-table-column>
            <el-table-column label="绑定状态">
              <template #default="scope"
                >{{ getauthStatus(scope.row.authStatus) }}
              </template>
            </el-table-column>
            <el-table-column label="备注">
              <template #default="scope">{{
                scope.row.message == null ? "-" : scope.row.message
              }}</template>
            </el-table-column>
            <el-table-column label="绑定时间">
              <template #default="scope">{{
                scope.row.createTime == null ? "-" : scope.row.createTime
              }}</template>
            </el-table-column>
            <el-table-column label="解绑时间">
              <template #default="scope">{{
                scope.row.updateTime == null ? "-" : scope.row.updateTime
              }}</template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template #default="scope">
                <div
                  class="flex-nowrap"
                  v-if="scope.row.authStatus != 2 && scope.row.authStatus != 3"
                >
                  <span
                    class="button-font-color"
                    @click="confirmEvent(scope.row.id)"
                  >
                    <!-- 将关系绑定id作为整数传递给 confirmEvent -->
                    <icon-font type="icon-bangding" />
                    解除绑定
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 100, 500]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 筛选查询弹窗 -->
    <div>
      <el-dialog
        title="查询"
        v-model="theQueryDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="30%"
      >
        <div>
          <el-form
            label-position="left"
            label-width="100px"
            size="small"
            :model="searchForm"
            ref="upform"
          >
            <div class="form-out-box p-0-30">
              <el-form-item label="虚拟号">
                <el-input
                  v-model="searchForm.telXList"
                  placeholder="请输入查询的号码(换行可输入多个)"
                  clearable
                  type="textarea"
                  :rows="3"
                ></el-input>
              </el-form-item>
              <el-form-item label="主叫号码">
                <el-input
                  v-model="searchForm.telAList"
                  placeholder="请输入查询的号码(换行可输入多个)"
                  type="textarea"
                  :rows="3"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="企业名称">
                <el-select
                  v-model="searchForm.uid"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in user_list"
                    :key="index"
                    :label="
                      `${item.nickname || ''}--${item.company || ''}--(Id:${
                        item.uid
                      })`
                    "
                    :value="item.uid"
                  >
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1 ? 'no-red' : ''">
                        <span>{{ item.nickname }}--</span>
                        <span>{{ item.company }}--</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建日期：">
                <el-date-picker
                  v-model="valueTime"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  clearable
                  value-format="YYYY-MM-DD HH:mm:ss"
                  size="mini"
                  :default-time="[
                    new Date(2000, 1, 1, 0, 0, 0),
                    new Date(2000, 2, 1, 23, 59, 59),
                  ]"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="号码状态">
                <el-radio v-model="searchForm.authStatus" label=""
                  >全部</el-radio
                >
                <el-radio v-model="searchForm.authStatus" label="0"
                  >绑定中</el-radio
                >
                <el-radio v-model="searchForm.authStatus" label="1"
                  >绑定成功</el-radio
                >
                <el-radio v-model="searchForm.authStatus" label="2"
                  >绑定失败</el-radio
                >
                <el-radio v-model="searchForm.authStatus" label="3"
                  >已解绑</el-radio
                >
              </el-form-item>
            </div>
            <el-form-item>
              <div class="flex-nowrap-flex-end">
                <el-button class="cancel" plain @click="searchTapReset" round>
                  重置
                </el-button>
                <el-button class="confirmAdd" @click="searchTapFun" round>
                  查询
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <!-- 勾选解除弹窗 -->
    <div>
      <el-dialog
        title="解除绑定"
        v-model="theQueryDialog1"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="30%"
      >
        <div>
          <p>
            将对虚拟号<span
              v-for="(item, index) in multipleSelection"
              :key="index"
              >{{ item.telX }}
              <span v-if="index < multipleSelection.length - 1">、</span> </span
            >解绑,请再次确定
          </p>
        </div>
        <el-form-item>
          <div class="flex-nowrap-flex-end">
            <el-button
              class="cancel"
              @click="theQueryDialog1 = false"
              plain
              round
            >
              取消
            </el-button>
            <el-button class="confirmAdd" @click="searchTapFun1" round>
              确定
            </el-button>
          </div>
        </el-form-item>
      </el-dialog>
    </div>
    <!-- 批量解除绑定 -->
    <el-dialog
      title="解除绑定"
      v-model="theQueryDialog2"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="30%"
    >
      <div>
        <el-form
          label-position="left"
          label-width="100px"
          size="small"
          :model="searchForm"
          ref="upform"
        >
          <div class=" p-0-30">
            <el-form-item label="虚拟号">
              <el-input
                v-model="searchForm1.telXList"
                placeholder="请输入要解除的虚拟号(换行可输入多个)"
                clearable
                type="textarea"
                :rows="8"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button class="cancel" plain @click="searchTapReset2" round>
                取消
              </el-button>
              <el-button class="confirmAdd" @click="searchTapFun2" round>
                确定
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 批量绑定 -->
    <el-dialog
      title="批量绑定"
      v-model="theQueryDialog3"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="30%"
    >
      <div>
        <el-form
          label-position="left"
          label-width="100px"
          size="small"
          :model="searchForm"
          ref="upform"
        >
          <div class="p-0-30">
            <el-form-item label="企业名称">
              <el-select
                v-model="searchForm.uid"
                placeholder="请选择"
                style="width: 100%"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in user_list"
                  :key="index"
                  :label="
                    `${item.nickname || ''}--${item.company || ''}--(Id:${
                      item.uid
                    })`
                  "
                  :value="item.uid"
                >
                  <div class="flex-nowrap-space-between">
                    <div :class="item.status == 1 ? 'no-red' : ''">
                      <span>{{ item.nickname }}--</span>
                      <span>{{ item.company }}--</span>
                      <span class="ml-5">(Id:{{ item.uid }})</span>
                    </div>
                    <div v-if="item.status == 1" class="no-red">禁</div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="绑定">
              <el-input
                v-model="searchForm2.telXList"
                placeholder="请输入主叫-小号 列如18866666666-13555569897(换行可输入多个)"
                clearable
                type="textarea"
                :rows="8"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button class="cancel" plain @click="searchTapReset3" round>
                取消
              </el-button>
              <el-button class="confirmAdd" @click="searchTapFun3" round>
                批量绑定
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 批量解绑成功回显 -->
    <el-dialog
        title="提示"
        v-model="theQueryDialog4"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="30%"
      >
        <div>
          <p  v-for="(item, index) in multipleSelection1"
              :key="index">
          {{ item }}
          </p>
        </div>
        <el-form-item>
          <div class="flex-nowrap-flex-end">
            <el-button class="confirmAdd"  @click="theQueryDialog4 = false" round>
              确定
            </el-button>
          </div>
        </el-form-item>
      </el-dialog>
    <JobNumbeModal :type="jobNumbeModalType" ref="jobNumbeModal" />
  </div>
</template>
<script>
import { virtualNumberList } from "@/api/web/virtualNumber";
import {
  relationshipUnbinding,
  batchaAuthUnBind,
  batchAuthBind
} from "@/api/web/virtualNumber";
import { message } from "ant-design-vue";
import { IconFont } from "@/utils/iconfont";
import { toRaw } from "vue";
import { getDate } from "@/utils/timeToChoose.js";
import userts from "@/api/web/user";
import { tuple } from "ant-design-vue/lib/_util/type";
import JobNumbeModal from "./component/jobNumbeModal.vue";
export default {
  name: "Number",
  components: {
    IconFont,
    JobNumbeModal
  },
  data() {
    return {
      label: [],

      tableData: [], //数据列表

      theQueryDialog: false, //弹窗--查询
      theQueryDialog1: false, //弹窗--勾选
      theQueryDialog2: false, //弹窗--批量
      theQueryDialog3: false, //弹窗-批量绑定
        theQueryDialog4: false, //弹窗-批量解绑弹窗回显
      activeNames: [], //折叠面板

      tableHeight: "", //表格高度
      operationType: 0, //弹窗提交按钮文字状态
      currentPage: 1, //分页
      total: 0, //总数据条数

      industryTreeProps: {
        multiple: false,
        checkStrictly: true, //Select any level of options
      },
      unIndustryTreeProps: {
        multiple: true,
        checkStrictly: true, //Select any level of options
      },
      user_list: [],
      multipleSelection: [], // 选中小号的集合
     multipleSelection1:[], //批量解绑成功回显
      searchForm: {
        page: 1,
        pageSize: 10,
        uid: "",
        authStatus: "",
        status: "",
        telAList: "",
        telXList: "",
        phone: "",
        phone1: "",
        bindId: "",
        createTimeBegin: "",
        createTimeEnd: "",
      },
      //批量解除绑定
      searchForm1: {
        telXList: "",
        phone: "",
      },
      //批量绑定
      searchForm2: {
        telXList: "",
        phone: "",
      },
      valueTime: [], //选中时间段
      allIds: [],
      defaultTime: [new Date(2000, 1, 1), new Date(2000, 2, 1)], //默认时间 '12:00:00', '08:00:00'

      loading: true, // loding 状态启用
      importType: 1,

      jobNumbeModalType: ""
    };
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight;
  },
  mounted() {
    // amount.industryidlist().then((res) => {
    //   this.label = res.data
    // })

    this.getNumberList();
    this.getUserList1();
    
  },
  computed: {},
  methods: {
      searchQuery(){
       this.theQueryDialog=true
       this.searchForm.telXList=''
       this.searchForm.telAList=''
    },
    // 批量解除
    packBranch() {
      if (this.allIds.length < 1) {
        this.$notify({
          title: "失败",
          message: "请先勾需要解绑的号码",
          type: "waring",
        });
      } else {
        this.theQueryDialog1 = true;
      }
    },
    searchTapFun1() {
      this.confirmEvent1();
      this.theQueryDialog1 = false;
    },
    searchTapFun2() {
      if (!this.searchForm1.telXList || !this.searchForm1.telXList.trim()) {
        this.$notify({
          title: "失败",
          message: "请输入要解绑的号码",
          type: "waring",
        });
        return;
      }
      this.confirmEvent2();
      this.searchForm1.telXList = "";
      this.theQueryDialog2 = false;
        
    },
    searchTapReset2() {
      this.theQueryDialog2 = false;
      this.searchForm1.telXList = "";
    },
    confirmEvent2() {
      let arr = this.searchForm1.telXList.split(/\r\n|\r|\n/);
      // 去除首尾空格，并过滤掉空白行
      this.searchForm1.phone = [
        ...new Set(arr.map((phoneNumber) => phoneNumber.trim())),
      ].filter((trimmedPhoneNumber) => trimmedPhoneNumber !== "");
      batchaAuthUnBind({
        numberList: this.searchForm1.phone,
      }).then((res) => {
        if (res.code == 200) {
         this.multipleSelection1=res.data
         this.theQueryDialog4=true
         // let resultArray = [];
          // res.data.forEach(function(item, index) {
          //   resultArray.push("提示" + (index + 1) + ": " + item);
          // });
          // this.$notify({
          //   title: "成功",
          //   message: resultArray.join("<br>"),
          //   dangerouslyUseHTMLString: true,
          //   type: "success",
          // });
          this.getNumberList();
         
        } else {
          this.$notify({
            title: "失败",
            message: res.message || res.msg,
            type: "error",
          });
        }
      });
    },
    searchTapFun3() {
      if (
        !this.searchForm2.telXList ||
        !this.searchForm2.telXList.trim() ||
        !this.searchForm.uid
      ) {
        this.$notify({
          title: "失败",
          message: "请选择企业或勾选号码",
          type: "waring",
        });
        return;
      }
      this.confirmEvent3();
      this.searchForm2.telXList = "";
      this.searchForm.uid = "";
      this.theQueryDialog3 = false;
    },
    searchTapReset3() {
      this.theQueryDialog3 = false;
      this.searchForm2.telXList = "";
      this.searchForm.uid = "";
    },
    confirmEvent3() {
      let arr = this.searchForm2.telXList.split(/\r\n|\r|\n/);
      // 去除首尾空格，并过滤掉空白行
      this.searchForm2.phone = [
        ...new Set(arr.map((phoneNumber) => phoneNumber.trim())),
      ].filter((trimmedPhoneNumber) => trimmedPhoneNumber !== "");
      batchAuthBind({
        uid: this.searchForm.uid,
        numberList: this.searchForm2.phone,
      }).then((res) => {
        if (res.code == 200) {
           this.multipleSelection1=res.data
         this.theQueryDialog4=true
          // let resultArray = [];
          // res.data.forEach(function(item, index) {
          //   resultArray.push("提示" + (index + 1) + ": " + item);
          // });
          // this.$notify({
          //   title: "成功",
          //   message: resultArray.join("<br>"),
          //   dangerouslyUseHTMLString: true,
          //   type: "success",
          // });
          this.getNumberList();
        } else {
          this.$notify({
            title: "失败",
            message: res.message || res.msg,
            type: "error",
          });
        }
      });
    },
    // 列表多选--操作
    handleSelectionChange(val) {
      let newIds = [];
      val.forEach((item) => {
        newIds.push(item.id);
      });
      this.multipleSelection = val;
      this.allIds = newIds;
    },
    // 获取用户列表
    getUserList1() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
    },
    // 重置搜索项
    searchTapReset(kind) {
      this.searchForm = {
        page: this.currentPage,
        pageSize: 10,
        authStatus: "",
        uid: "",
        telAList: "",
        telXList: "",
        bindId: "",
        createTimeBegin: "",
        createTimeEnd: "",
      };
      this.valueTime = "";
      this.searchArea = [];
      if (kind == 1) {
        this.getNumberList();
        this.theQueryDialog = false;
      }
    },
    // 搜索项
    searchTapFun() {
      if (this.valueTime) {
        this.searchForm.createTimeBegin = this.valueTime[0];
        this.searchForm.createTimeEnd = this.valueTime[1];
      } else {
        this.searchForm.createTimeBegin = "";
        this.searchForm.createTimeEnd = "";
      }
      this.getNumberList();
      // this.searchForm.telXList = "";
      // this.searchForm.telAList = "";
      this.theQueryDialog = false;
      this.valueTime = "";
    },
    // 解除绑定
    confirmEvent(id) {
      if (id) {
        this.allIds[0] = id;
      }
      let dataName = "";
      this.$confirm("将解除绑定，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        relationshipUnbinding({ ids: this.allIds }) // 将从点击事件中获取的 ids 参数传递给接口
          .then((res) => {
            this.dataName = res.data[0];
            this.$notify({
              title: "成功",
              message: this.dataName,
              type: "success",
            });
            this.allIds = [];
            this.getNumberList();
          });
      });
    },
    packBranch1() {
      this.theQueryDialog2 = true;
    },
    // 解除绑定
    confirmEvent1(id) {
      if (id) {
        this.allIds[0] = id;
      }
      let dataName = "";
      relationshipUnbinding({ ids: this.allIds }) // 将从点击事件中获取的 ids 参数传递给接口
        .then((res) => {
          this.dataName = res.data[0];
          this.$notify({
            title: "成功",
            message: this.dataName,
            type: "success",
          });
          this.allIds = [];
          this.getNumberList();
        });
    },

    getauthStatus(key) {
      switch (key) {
        case 0:
          return "绑定中";
          break;
        case 1:
          return "绑定成功";
          break;
        case 2:
          return "绑定失败";
          break;
        case 3:
          return "已解绑";
          break;
        default:
          return "--";
          break;
      }
    },
    // 获取列表
    getNumberList() {
      this.loading = true;
      let arr = this.searchForm.telXList.split(/\r\n|\r|\n/);
      let arr1 = this.searchForm.telAList.split(/\r\n|\r|\n/);
      // 去除首尾空格，并过滤掉空白行
      this.searchForm.phone = [
        ...new Set(arr.map((phoneNumber) => phoneNumber.trim())),
      ].filter((trimmedPhoneNumber) => trimmedPhoneNumber !== "");
      this.searchForm.phone1 = [
        ...new Set(arr1.map((phoneNumber) => phoneNumber.trim())),
      ].filter((trimmedPhoneNumber) => trimmedPhoneNumber !== "");
      virtualNumberList({
        page:this.currentPage,
        pageSize:this.searchForm.pageSize,
        uid: this.searchForm.uid,
        authStatus: this.searchForm.authStatus,
        status: this.searchForm.status,
        telAList: this.searchForm.phone1,
        telXList: this.searchForm.phone,
        bindId: this.searchForm.bindId,
        createTimeBegin: this.searchForm.createTimeBegin,
        createTimeEnd: this.searchForm.createTimeEnd,
      }).then((res) => {
        if (res.code==200) {
           this.tableData = res.data.records;
        this.loading = false;
        this.total = res.data.total;
        }
      
       
      });
    },
    // 分页--条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.getNumberList();
    },
    // 分页--页码
    handleCurrentChange(val) {
      console.log(val);
      this.searchForm.page = val;
      this.currentPage = val;
      this.getNumberList();
    },
  },
};
</script>
<style lang="scss" scoped>
.number {
  padding-right: 10px;
}

.showIdsList {
  min-height: 30px;
  max-height: 260px;
  overflow-y: overlay;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-left: 16px;
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}

.confirmAdd {
  background: #637dff;
  color: #fff;
}

.form-out-box {
  height: 400px;
  margin-bottom: 20px;
  overflow-y: overlay;
}

.yes-green-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  color: #409eff;
  background: #ecf5ff;
  border: 1px solid #ecf5ff;
}

.no-red-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  background: #f4f4f5;
  border: 1px solid #f4f4f5;
}

.redItem {
  color: #f0142f;
}

.showErrList {
  height: 200px;
  overflow: scroll;
  width: 250px;
}

::v-deep(.checkbox .el-checkbox) {
  display: block;
}

.noticetext span {
  color: #f0142f;
}
</style>
